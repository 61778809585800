import Axios from "axios";
import { utils } from "./http-common";
import { AppVersion } from "@/store/devices/types";
import { store } from "@/store";

export class CardsApi {
	static async fetchAllOrganisations() {
		return Axios.get(utils.generateUrl(`organisations/all`), {
			params: {
				pagesize: -1
			},
			headers: await utils.generateHeader()
		});
	}

	static async fetchLinkedDevices(id: number) {
		return Axios.get(utils.generateUrl(`api/admin/Terminal/orgid/${id}`, process.env.VUE_APP_CARDS_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchLinkedDeviceHistory(id: number, date: string) {
		return Axios.get(utils.generateUrl(`api/admin/Terminal/${id}/History`, process.env.VUE_APP_CARDS_URL), {
			params: {
				historyDate: date
			},
			headers: await utils.generateHeader()
		});
	}

	static async fetchLinkedDeviceLinkHistory(id: number) {
		return Axios.get(utils.generateUrl(`api/admin/Terminal/${id}/TerminalHistory`, process.env.VUE_APP_CARDS_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchDeviceById(id: number) {
		return Axios.get(utils.generateUrl(`api/admin/Terminal/${id}`, process.env.VUE_APP_CARDS_URL), {
			headers: await utils.generateHeader()
		});
	}

	// fetch Organisation based on linked terminal
	static async fetchOrganisationByCode(code: string) {
		return Axios.get(utils.generateUrl(`organisations/code/${code}`), {
			headers: await utils.generateHeader()
		});
	}

	// fetch all app versions
	static async fetchAppVersion() {
		return Axios.get(utils.generateUrl(`api/admin/AppVersion`, process.env.VUE_APP_CARDS_URL), {
			headers: await utils.generateHeader()
		});
	}

	// create app version
	static async createAppVersion(data: AppVersion) {
		return Axios.post(
			utils.generateUrl(`api/admin/AppVersion`, process.env.VUE_APP_CARDS_URL),
			{ ...data },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	// update app version
	static async updateAppVersion(id: number, data: AppVersion) {
		return Axios.put(
			utils.generateUrl(`api/admin/AppVersion/${id}`, process.env.VUE_APP_CARDS_URL),
			{ ...data },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	// fetch Organisation based on linked terminal
	static async linkDevice(params: object) {
		return Axios.post(utils.generateUrl(`api/Terminal`, process.env.VUE_APP_CARDS_URL), params, {
			headers: await utils.generateHeader()
		});
	}

	// fetch Organisation based on linked terminal
	static async unlinkDevice(deviceId: number) {
		return Axios.delete(utils.generateUrl(`api/Terminal/${deviceId}`, process.env.VUE_APP_CARDS_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchFailedTransactions() {
		return Axios.get(utils.generateUrl(`api/admin/Recharge/FailedTransactions`, process.env.VUE_APP_CARDS_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async retryFailedTransaction(rechargeId: number) {
		return Axios.post(utils.generateUrl(`api/admin/Recharge/ResendFailedRecharge/${rechargeId}`, process.env.VUE_APP_CARDS_URL), null, {
			headers: await utils.generateHeader()
		});
	}

	static async searchPan(panData: any) {
		return Axios.get(utils.generateUrl(`api/admin/CardEvent/Pan`, process.env.VUE_APP_CARDS_URL), {
			params: {
				...panData
			},
			headers: await utils.generateHeader()
		});
	}

	static async downloadSlip(transactionReference: string) {
		Axios.get(utils.generateUrl(`api/admin/CardEvent/SlipImage/${transactionReference}`, process.env.VUE_APP_CARDS_URL), {
			headers: await utils.generateHeader(),
			responseType: "blob"
		})
			.then(response => {
				const data = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = data;
				const file = response.headers["content-disposition"].split("filename=")[1];
				const filename = file.replace('"', "");
				link.setAttribute("download", `${filename}`);
				link.click();
				store.dispatch("notifications/success", "File downloaded, please check your download folder.", { root: true });
			})
			.catch(error => {
				store.dispatch("notifications/error", `Failed to download file: ${error}`, { root: true });
			});
	}

	static async fetchRejectedEvents(deviceserial: number) {
		return Axios.get(utils.generateUrl(`api/admin/RejectedIveriEvents/${deviceserial}`, process.env.VUE_APP_CARDS_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async retryRejectedEvent(eventId: number) {
		return Axios.post(utils.generateUrl(`api/admin/RejectedIveriEvents/${eventId}`, process.env.VUE_APP_CARDS_URL), null, {
			headers: await utils.generateHeader()
		});
	}
}
