import { Module } from "vuex";
import { getters } from "@/store/devices/getters";
import { actions } from "@/store/devices/actions";
import { mutations } from "@/store/devices/mutations";
import { RootState } from "@/store/types";
import { DevicesState } from "@/store/devices/types";
import { statusFactory } from "../utils";

export const state: DevicesState = {
	devices: [],
	deviceHistory: [],
	selectedDevice: 0,
	searchedDevice: undefined,
	failedTransactions: [],
	panTransactions: [],
	rejectedEvents: [],
	appVersions: [],
	status: statusFactory()
};

const namespaced: boolean = true;

export const devices: Module<DevicesState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
