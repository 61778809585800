import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { DevicesState } from "@/store/devices/types";

export const getters: GetterTree<DevicesState, RootState> = {
	selectedDeviceHistory(state) {
		if (!state.selectedDevice) return {};

		return state.deviceHistory;
	}
};
