import { DevicesState } from "@/store/devices/types";
import { MutationTree } from "vuex";

export const mutations: MutationTree<DevicesState> = {
	setSelectedOrganisationDevices(state, payload) {
		state.devices = payload;
		state.status.loading = false;
	},

	setSelectedDeviceHistory(state, payload) {
		state.selectedDevice = payload.id;
		state.deviceHistory = payload.data;
		state.status.loading = false;
	},

	setSearchedDevice(state, payload) {
		state.selectedDevice = payload.TerminalId;
		state.searchedDevice = {
			device: {
				TerminalId: payload.TerminalId,
				ShopCode: payload.ShopCode,
				Description: payload.Description
			},
			name: payload.name,
			id: payload.id
		};
		state.status.loading = false;
	},

	clearSearchedDevice(state) {
		state.selectedDevice = 0;
		state.searchedDevice = undefined;
	},

	setAppVersions(state, payload) {
		state.appVersions = payload;
		state.status.loading = false;
	},

	unlinkDevice(state) {
		state.selectedDevice = 0;
		state.searchedDevice = undefined;
		state.status.loading = false;
	},

	setFailedTransactions(state, payload) {
		state.failedTransactions = payload;
		state.status.loading = false;
	},

	setPanTransactions(state, payload) {
		state.panTransactions = payload;
		state.status.loading = false;
	},

	setRejectedEvents(state, payload) {
		state.rejectedEvents = payload;
		state.status.loading = false;
	},

	loading(state) {
		state.status.error = false;
		state.status.loading = true;
		state.status.success = false;
	},
	success(state) {
		state.status.error = false;
		state.status.loading = false;
		state.status.success = true;
	},
	error(state) {
		state.status.error = true;
		state.status.loading = false;
		state.status.success = false;
	}
};
