import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { DevicesState, AppVersion } from "@/store/devices/types";
import { CardsApi } from "@/api/cards-api";
import { OrganisationsApi } from "@/api/organisation-api";
import { CardTransactions } from "@/store/devices/types";

export const actions: ActionTree<DevicesState, RootState> = {
	async fetchLinkedDevices({ commit, state }, id: number) {
		try {
			commit("loading");
			// clean device list
			state.devices = [];
			const response = await CardsApi.fetchLinkedDevices(id);
			commit("setSelectedOrganisationDevices", response.data);
		} catch (error) {
			commit("error");
		}
	},
	async fetchLinkedDeviceHistory({ commit }, params: { id: number; date: string }) {
		try {
			commit("loading");
			const response = await CardsApi.fetchLinkedDeviceHistory(params.id, params.date);

			// go through each transaction
			let transactionData: object[] = [];
			response.data.CardTransactions.forEach((transaction: CardTransactions) => {
				let reason: any = "";
				let description: any = "";

				if (transaction.CardResult.Error != null) {
					reason = transaction.CardResult.Error;
				} else {
					if (transaction.CardResult.IveriMessage.Transaction.Result != null) {
						reason = transaction.CardResult.IveriMessage.Transaction.Result.Description;
						description = transaction.CardResult.IveriMessage.Transaction.Result.AcquirerDescription;
					}
				}

				const transactionObj = {
					status: transaction.CardEventStatus,
					amount: transaction.CardEvent.Amount,
					time: transaction.CardEvent.TerminalTime,
					reason: reason,
					description: description
				};
				transactionData.push(transactionObj);
			});

			const historyData = {
				TotalTransactions: response.data.TotalTransactions,
				TotalQtySuccess: response.data.TotalQtySuccess,
				TotalAmountSuccess: response.data.TotalAmountSuccess,
				TotalQtyFailed: response.data.TotalQtyFailed,
				TotalAmountFailed: response.data.TotalAmountFailed,
				CardTransactions: transactionData
			};

			let responseData = { id: params.id, data: historyData };
			commit("setSelectedDeviceHistory", responseData);
			return responseData;
		} catch (error) {
			commit("error");
		}
	},
	async fetchLinkedDeviceLinkHistory({ commit }, id: number) {
		try {
			commit("loading");
			const response = await CardsApi.fetchLinkedDeviceLinkHistory(id);
			let responseData = { id: id, data: response.data };
			commit("setSelectedDeviceHistory", responseData);
			return responseData;
		} catch (error) {
			commit("error");
		}
	},
	async fetchDeviceById({ commit }, id: number) {
		try {
			commit("loading");
			const response = await CardsApi.fetchDeviceById(id);

			let data = response.data;

			let code = response.data.ShopCode;

			if (code != null) {
				// GET ASSOCIATED ORGANISATION
				let code = response.data.ShopCode;
				const results = await CardsApi.fetchOrganisationByCode(code);
				data = { ...response.data, ...results.data };
			} else {
				// device not linked
				data = { ...response.data, name: null };
			}

			commit("setSearchedDevice", data);
		} catch (error) {
			commit("error");
		}
	},
	async fetchAppVersion({ commit, dispatch }) {
		try {
			commit("loading");
			const response = await CardsApi.fetchAppVersion();

			response.data.map((item: any) => {
				let dateTime = item.ActiveDate.split("T");
				let time = dateTime[1].split(".");
				dateTime[1] = time[0];
				item.ActiveDate = dateTime;
			});
			commit("setAppVersions", response.data);
		} catch (error) {
			commit("error");
			dispatch("notifications/error", error.message, { root: true });
		}
	},
	async createAppVersion({ commit, dispatch }, data: AppVersion) {
		try {
			commit("loading");
			await CardsApi.createAppVersion(data);
			dispatch("fetchAppVersion");
			dispatch("notifications/success", "Create successful", { root: true });
		} catch (error) {
			commit("error");
			dispatch("notifications/error", error.message, { root: true });
		}
	},
	async updateAppVersion({ commit, dispatch }, data: AppVersion) {
		try {
			commit("loading");
			if (data.Id != null) {
				const id = data.Id;
				delete data.Id;
				await CardsApi.updateAppVersion(id, data);
			}
			dispatch("fetchAppVersion");
			dispatch("notifications/success", "Update successful", { root: true });
		} catch (error) {
			commit("error");
			dispatch("notifications/error", error.message, { root: true });
		}
	},
	async linkDevice({ commit, dispatch }, params: object) {
		try {
			commit("loading");
			const response = await CardsApi.linkDevice(params);
			let data = response.data;
			commit("setSearchedDevice", data);
		} catch (error) {
			dispatch("notifications/error", error.response.data.Message, { root: true });
			commit("error");
		}
	},
	async unlinkDevice({ commit, dispatch }, deviceId: number) {
		try {
			commit("loading");
			await CardsApi.unlinkDevice(deviceId);
			commit("unlinkDevice");
			dispatch("notifications/success", "Device unlinked.", { root: true });
		} catch (error) {
			commit("error");
		}
	},
	async fetchFailedTransactions({ commit }) {
		try {
			commit("loading");
			const response = await CardsApi.fetchFailedTransactions();
			commit("setFailedTransactions", response.data);
		} catch (error) {
			commit("error");
		}
	},
	async retryFailedTransaction({ commit, dispatch }, rechargeId: number) {
		try {
			commit("loading");
			await CardsApi.retryFailedTransaction(rechargeId);
			dispatch("notifications/success", "Recharge successful.", { root: true });
		} catch (error) {
			commit("error");
			dispatch("notifications/error", error.message, { root: true });
		}
	},
	async fetchOrganisationById({ commit, dispatch }, orgId: number) {
		try {
			commit("loading");
			let result = await OrganisationsApi.fetchOrganisationById(orgId);
			commit("success");
			return result.data;
		} catch (error) {
			commit("error");
			dispatch("notifications/error", error.message, { root: true });
		}
	},
	async fetchPAN({ commit }, panData: any) {
		commit("loading");
		let response = await CardsApi.searchPan(panData);

		let result: any = [];

		response.data.CardTransactions.forEach((transaction: CardTransactions) => {
			let obj = {
				amount: transaction.CardResult.IveriMessage.Transaction.DisplayAmount,
				terminalId: transaction.CardEvent.TerminalId,
				shopCode: transaction.CardEvent.ShopCode,
				orgName: transaction.CardEvent.OrgName,
				issuer: transaction.CardResult.IveriMessage.Transaction.Issuer,
				pan: transaction.CardResult.IveriMessage.Transaction.PAN,
				terminalTime: transaction.CardEvent.TerminalTime,
				acquirerCode: transaction.CardResult.IveriMessage.Transaction.Result.AcquirerCode,
				acquirerDescription: transaction.CardResult.IveriMessage.Transaction.Result.AcquirerDescription,
				status: transaction.CardEventStatus,
				transactionReference: transaction.TransactionReference
			};

			result.push(obj);
		});
		commit("setPanTransactions", result);
	},

	async downloadSlip({ dispatch }, transactionReference: string) {
		await CardsApi.downloadSlip(transactionReference);
	},

	async fetchRejectedEvents({ commit, dispatch }, deviceSerial) {
		try {
			commit("loading");
			const response = await CardsApi.fetchRejectedEvents(deviceSerial);
			commit("setRejectedEvents", response.data);
		} catch (error) {
			commit("error");
			dispatch("notifications/error", error, { root: true });
		}
	},

	async retryRejectedEvent({ commit, dispatch }, data) {
		try {
			commit("loading");
			const response = await CardsApi.retryRejectedEvent(data.event);
			dispatch("fetchRejectedEvents", data.deviceId);
		} catch (error) {
			commit("error");
			dispatch("notifications/error", error, { root: true });
		}
	}
};
